<template>
  <MglMap
    :access-token="accessToken"
    :map-style="mapStyle"
    :center="centre"
    :zoom="zoom"
    :max-zoom="17"
    @load="onMapLoaded"
    @click="closePopup"
  >
    <MglFullscreenControl position="bottom-right" />
    <MglNavigationControl position="bottom-right" />

    <MglGeojsonLayer
      :source-id="geoJsonPoints.data.id"
      :source="geoJsonPoints"
      :layer-id="clustersLayer.id"
      :layer="clustersLayer"
      @click="clickedClusteredLayer"
    />
    <MglGeojsonLayer
      :source-id="geoJsonPoints.data.id"
      :source="geoJsonPoints"
      :layer-id="clusterCountLayer.id"
      :layer="clusterCountLayer"
    />
    <!-- Line, arrows and accuracy circles -->
    <MglGeojsonLayer
      v-if="!shouldClusterLocations"
      :source-id="geoJsonLine.data.id"
      :source="geoJsonLine"
      :layer-id="directionalLineLayer.id"
      :layer="directionalLineLayer"
    />
    <MglGeojsonLayer
      v-if="!isGreyScale && !shouldClusterLocations"
      :source-id="geoJsonLine.data.id"
      :source="geoJsonLine"
      :layer-id="directionalArrowLayer.id"
      :layer="directionalArrowLayer"
    />
    <MglGeojsonLayer
      :source-id="accuracyCircles.data.id"
      :source="accuracyCircles"
      :layer-id="accuracyCircleLayer.id"
      :layer="accuracyCircleLayer"
    />
    <!-- Ordering of these is important - the last ones display ontop -->

    <!-- Item location points -->
    <MglGeojsonLayer
      :source-id="geoJsonPoints.data.id"
      :source="geoJsonPoints"
      :layer-id="unclusteredLayer.id"
      :layer="unclusteredLayer"
      @click="clickedUnclusteredLayer"
    />
    <MglGeojsonLayer
      v-if="includesFirstPoint && !shouldClusterLocations && itemsWithCoords.length > 1"
      :source-id="geoJsonFirstPoint.data.id"
      :source="geoJsonFirstPoint"
      :layer-id="firstPointLayer.id"
      :layer="firstPointLayer"
      @click="clickedUnclusteredLayer"
    />
    <MglGeojsonLayer
      v-if="!shouldClusterLocations && !latestPointIsUncertain"
      :source-id="geoJsonLatestPoint.data.id"
      :source="geoJsonLatestPoint"
      :layer-id="latestPointLayer.id"
      :layer="latestPointLayer"
      @click="clickedUnclusteredLayer"
    />
    <MglGeojsonLayer
      v-if="!shouldClusterLocations && latestPointIsUncertain"
      :source-id="geoJsonPenultimatePoint.data.id"
      :source="geoJsonPenultimatePoint"
      :layer-id="penultimatePointLayer.id"
      :layer="penultimatePointLayer"
      @click="clickedUnclusteredLayer"
    />

    <MglMarker
      v-if="popUpCoordinates !== null"
      :key="popupKey"
      :coordinates="popUpCoordinates"
    >
      <div slot="marker" />
      <MglPopup
        :ref="'popup'"
        :showed="shouldDisplayPopup"
        :close-button="false"
        :close-on-click="true"
      >
        <slot name="popup-content" />
      </MglPopup>
    </MglMarker>
  </MglMap>
</template>
<script>
import mapbox from 'mapbox-gl';
import {
  MglMap,
  MglMarker,
  MglPopup,
  MglNavigationControl,
  MglFullscreenControl,
  MglGeojsonLayer,
} from 'vue-mapbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import circle from '@turf/circle';
import union from '@turf/union';
import variables from '../../../styles/_variables.scss';
import colours from '../../../styles/_updated-variables.scss';

let map = null;
const createDeepCopy = (x) => (JSON.parse(JSON.stringify(x)));
const MAX_ERROR = 2; // km
const MAX_ERROR_UNCERTAIN = 8; // km

function unionMany(arrayOfPolygons) {
  let output;
  if (arrayOfPolygons.length <= 1) {
    return arrayOfPolygons;
  }

  output = union(arrayOfPolygons[0], arrayOfPolygons[1]);
  for (let i = 2; i < arrayOfPolygons.length; i++) {
    output = union(output, arrayOfPolygons[i]);
  }
  return output;
}
export default {
  name: 'ReelablesWebMapBox',
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    MglNavigationControl,
    MglFullscreenControl,
    MglGeojsonLayer,
  },
  props: {
    // items are expected to be in reverse chronological order (latest first)
    items: { type: Array, default: () => [] },
    shouldClusterLocations: { type: Boolean, default: false },
    includesFirstPoint: { type: Boolean, default: true },
    isGreyScale: { type: Boolean, default: false },
    latestPointIsUncertain: { type: Boolean, default: false },
  },
  data() {
    const latestPoint = this.items.length > 0 ? this.items[0] : null;
    return {
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: this.isGreyScale
        ? 'mapbox://styles/steve-reelables/clk18xqtw00be01qyhn9u5exs'
        : 'mapbox://styles/steve-reelables/cleidbk6r000e01qb0ycvochz',
      centre: latestPoint
        ? { lon: latestPoint.longitude, lat: latestPoint.latitude }
        : { lon: -0.123, lat: 51.123 },
      hasFitBounds: false,
      zoom: 8,
      shouldDisplayPopup: false,
      popUpCoordinates: null,
      popupKey: 0,
      latestPointLayer: {
        id: 'latestPoint',
        // filter: ['!', ['has', 'point_count']],
        type: 'circle',
        paint: {
          'circle-color': this.isGreyScale ? colours.tealDark : colours.active,
          'circle-radius': 12,
          'circle-stroke-width': this.isGreyScale ? 6 : 3,
          'circle-stroke-color': this.isGreyScale ? colours.tealLight : 'white',
          'circle-stroke-opacity': this.isGreyScale ? 0.50 : 1,
        },
      },
      // Used when latest point is uncertain
      penultimatePointLayer: {
        id: 'penultimatePointLayer',
        type: 'circle',
        paint: {
          'circle-color': this.isGreyScale ? 'white' : colours.active,
          'circle-radius': 12,
          'circle-stroke-width': this.isGreyScale ? 6 : 3,
          'circle-stroke-color': this.isGreyScale ? colours.tealDark : 'white',
          'circle-stroke-opacity': this.isGreyScale ? 0.50 : 1,
          'circle-opacity': 0.6,
        },
      },
      firstPointLayer: {
        id: 'firstPoint',
        // filter: ['!', ['has', 'point_count']],
        type: 'circle',
        paint: {
          'circle-color': this.isGreyScale ? colours.gray2 : colours.start,
          'circle-radius': this.isGreyScale ? 8 : 12,
          'circle-stroke-width': this.isGreyScale ? 6 : 3,
          'circle-stroke-color': this.isGreyScale ? colours.gray2 : 'white',
          'circle-stroke-opacity': this.isGreyScale ? 0.50 : 1,
        },
      },
      clustersLayer: {
        id: 'clusters',
        filter: ['has', 'point_count'],
        type: 'circle',
        paint: {
          'circle-color': this.shouldClusterLocations ? colours.active : colours.gray,
          'circle-radius': 20,
          'circle-stroke-width': 3,
          'circle-stroke-color': 'white',
        },
        properties: {
          count: ['get', 'point_count'],
        },
      },
      unclusteredLayer: {
        id: 'unclustered-point',
        filter: ['!', ['has', 'point_count']],
        type: 'circle',
        paint: {
          // eslint-disable-next-line no-nested-ternary
          'circle-color': this.isGreyScale
            ? colours.tealLight
            : (this.shouldClusterLocations ? colours.active : colours.gray),
          'circle-radius': this.isGreyScale ? 6 : 8,
          'circle-stroke-width': this.isGreyScale ? 6 : 3,
          'circle-stroke-color': this.isGreyScale ? colours.tealLight : 'white',
          'circle-stroke-opacity': this.isGreyScale ? 0.50 : 1,
        },
      },
      clusterCountLayer: {
        id: 'cluster-count',
        filter: ['has', 'point_count'],
        type: 'symbol',
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-size': 16,
        },
      },
      directionalLineLayer: {
        id: 'DirectionalLines',
        type: 'line',
        paint: {
          'line-color': this.isGreyScale ? colours.tealLight : colours.start,
          'line-width': this.isGreyScale ? 3 : 1.5,
        },
      },
      directionalArrowLayer: {
        id: 'DirectionalArrows',
        type: 'symbol',
        paint: {},
        layout: {
          'symbol-placement': 'line',
          // this icon is loaded as part of the style
          'icon-image': 'my-triangle-start-colour',
          'icon-rotate': 90,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        },
      },
      accuracyCircleLayer: {
        id: 'accuracy-circle',
        type: 'fill',
        paint: {
          'fill-color': this.isGreyScale ? colours.tealLight : '#00ffff',
          'fill-opacity': 0.15,
        },
      },
    };
  },
  computed: {
    markerColour() {
      return variables.gotItBlue;
    },
    itemsWithCoords() {
      return this.items
        .filter(({ latitude, longitude }) => !Number.isNaN(Number(latitude))
              && !Number.isNaN(Number(longitude)));
    },
    geoJsonPoints() {
      return {
        type: 'geojson',
        cluster: this.shouldClusterLocations,
        clusterMaxZoom: 50,
        data: {
          id: 'geoJsonPoints',
          type: 'FeatureCollection',
          features:
          (this.shouldClusterLocations
            ? this.itemsWithCoords
            : this.itemsWithCoords.slice(
              this.latestPointIsUncertain ? 2 : 1,
              this.includesFirstPoint ? -1 : this.itemsWithCoords.length,
            ))
            .map(({ latitude, longitude, properties }) => (
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [parseFloat(longitude), parseFloat(latitude)],
                },
                properties,
              }
            )),
        },
      };
    },
    geoJsonLatestPoint() {
      return {
        type: 'geojson',
        data: {
          id: 'geojsonDataLatest',
          type: 'FeatureCollection',
          features: this.itemsWithCoords.length > 0
            ? [{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  parseFloat(this.itemsWithCoords[0].longitude),
                  parseFloat(this.itemsWithCoords[0].latitude)],
              },
              properties: this.itemsWithCoords[0].properties,
            }]
            : [],
        },
      };
    },
    geoJsonPenultimatePoint() {
      return {
        type: 'geojson',
        data: {
          id: 'geoJsonPenultimatePoint',
          type: 'FeatureCollection',
          features: this.itemsWithCoords.length > 1
            ? [{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  parseFloat(this.itemsWithCoords[1].longitude),
                  parseFloat(this.itemsWithCoords[1].latitude)],
              },
              properties: this.itemsWithCoords[1].properties,
            }]
            : [],
        },
      };
    },
    geoJsonFirstPoint() {
      return {
        type: 'geojson',
        data: {
          id: 'geojsonDataFirst',
          type: 'FeatureCollection',
          features: this.itemsWithCoords.length > 0
            ? [{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  parseFloat(this.itemsWithCoords[this.itemsWithCoords.length - 1].longitude),
                  parseFloat(this.itemsWithCoords[this.itemsWithCoords.length - 1].latitude)],
              },
              properties: this.itemsWithCoords[this.itemsWithCoords.length - 1].properties,
            }]
            : [],
        },
      };
    },
    geoJsonLine() {
      const arr = createDeepCopy(this.latestPointIsUncertain
        ? this.itemsWithCoords.slice(1)
        : this.itemsWithCoords);
      arr.sort((a, b) => ((a.properties.timestamp > b.properties.timestamp) ? 1 : -1));
      const arrows = [];
      for (let i = 0; i < arr.length - 1; i++) {
        arrows.push({
          start: [Number(arr[i].longitude),
            Number(arr[i].latitude)],
          end: [Number(arr[i + 1].longitude), Number(arr[i + 1].latitude)],
        });
      }
      function drawShortestLine(unfilteredStart, unfilteredEnd) {
        const start = createDeepCopy(unfilteredStart);
        const end = createDeepCopy(unfilteredEnd);
        if (Math.abs(unfilteredEnd[0] - unfilteredStart[0]) > 180
         && unfilteredEnd[0] > unfilteredStart[0]) {
          start[0] += 360 * Math.ceil((unfilteredEnd[0] - unfilteredStart[0]) / 360);
        }
        if (Math.abs(unfilteredEnd[0] - unfilteredStart[0]) > 180
         && unfilteredStart[0] > unfilteredEnd[0]) {
          end[0] += 360 * Math.ceil((unfilteredStart[0] - unfilteredEnd[0]) / 360);
        }
        const shortestLine = [start, end];
        return shortestLine;
      }

      return {
        type: 'geojson',
        data: {
          id: 'DirectionalArrows',
          type: 'FeatureCollection',
          features: arrows.map(({ start, end }) => (
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: drawShortestLine(start, end),
              },
            }
          )),
        },
      };
    },
    accuracyCircles() {
      let circles = this.itemsWithCoords
        .filter(({ error }) => !Number.isNaN(Number(error)))
        .map(({ latitude, longitude, error }, i) => {
          const circleData = circle(
            [
              parseFloat(longitude),
              parseFloat(latitude),
            ],
            Math.min(
              (error / 1000),
              (this.latestPointIsUncertain && i === 0 ? MAX_ERROR_UNCERTAIN : MAX_ERROR),
            ),
            { units: 'kilometers' },
          );
          return circleData;
        });
      // required to have at least 2 circles for turf to union
      if (circles.length === 1) {
        circles = [circles, circles].flat();
      }
      const unionCircles = [unionMany(circles)];
      return {
        type: 'geojson',
        data: {
          id: 'circleData',
          type: 'FeatureCollection',
          features: unionCircles,
        },
      };
    },
  },
  watch: {
    items() {
      this.fitBounds();
    },
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    // Do not remove
    this.mapbox = mapbox;
  },
  beforeDestroy() {
    // Need to remove layers before distroying and avoid errors being thrown
    try {
      if (map?.getLayer('cluster-count')) {
        map.removeLayer('cluster-count');
      }
      if (map?.getLayer('clusters')) {
        map.removeLayer('clusters');
      }
      if (map?.getLayer('unclustered-point')) {
        map.removeLayer('unclustered-point');
      }
    } catch (err) {
      // map is undefined
    }
  },
  methods: {
    onMapLoaded(event) {
      this.$emit('loaded');
      map = event.map;
      this.fitBounds();

      map.on('zoom', this.closePopup);
    },
    fitBounds() {
      if (!this.hasFitBounds && this.itemsWithCoords.length > 0) {
        this.hasFitBounds = true;
        if (this.isGreyScale) {
          // centre
          map.flyTo({
            center: [
              this.itemsWithCoords[0].longitude,
              this.itemsWithCoords[0].latitude,
            ],
          });
        } else {
          // fit to all points
          const longitudes = this.itemsWithCoords.map(({ longitude }) => (parseFloat(longitude)));
          const latitudes = this.itemsWithCoords.map(({ latitude }) => (parseFloat(latitude)));
          if (map) {
            map.fitBounds([
              Math.max(Math.min(...longitudes) - 2, -180), // Most western point
              Math.max(Math.min(...latitudes) - 2, -90), // Most southern point
              Math.min(Math.max(...longitudes) + 2, 180), // Most eastern point
              Math.min(Math.max(...latitudes) + 2, 90), // Most northern point
            ]);
          }
        }
      }
    },
    showPopup(coords) {
      this.popUpCoordinates = coords;
      this.shouldDisplayPopup = true;
      this.popupKey += 1;
    },
    closePopup() {
      this.shouldDisplayPopup = false;
    },
    parseEventData(event) {
      return {
        popupCoordinates: [event.mapboxEvent.lngLat.lng, event.mapboxEvent.lngLat.lat],
        features: event.map.queryRenderedFeatures(event.mapboxEvent.point, {
          layers: [event.layerId],
        }),
      };
    },
    clickedUnclusteredLayer(event) {
      const feature = this.parseEventData(event).features[0];
      this.showPopup(feature.geometry.coordinates);
      this.$emit('clickedUnclusteredItem', feature.properties);
    },
    clickedClusteredLayer(event) {
      const feature = this.parseEventData(event).features[0];
      event.map.getSource(this.geoJsonPoints.data.id)
        .getClusterLeaves(
          feature.id,
          feature.properties.point_count,
          0,
          (error, clusterFeatures) => {
            this.showPopup(feature.geometry.coordinates);
            event.map.on('zoom', this.closePopup);
            this.$emit('clickedClusteredItems', clusterFeatures
              ? clusterFeatures.map(({ properties }) => properties)
              : []);
          },
        );
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';

.mapboxgl-popup-close-button {
  color: $black;
}

.mapboxgl-canvas {
  position: relative !important;
}

.mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
  display: none !important;
}
</style>
